import * as Amplitude from '@amplitude/analytics-browser'
import { getUrlParam } from '../../../utils/UrlUtils'
import {
  type BrowserOptions,
  type ValidPropertyType,
} from '@amplitude/analytics-types'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

export const amplitude = Amplitude
export async function initAmplitude() {
  const options: BrowserOptions = {
    appVersion: window.KEYS.buildCommit || undefined,
    deviceId: getUrlParam('deviceId') || undefined,
    defaultTracking: {
      attribution: false,
    },
  }
  // we are initializing amplitude with user id undefined, we will set it later to our user id only when we have user email.
  // we are still sending events to amplitude, but when we have user email we will set the user id, and then amplitude will merge the events with the user id.
  await amplitude.init(window.KEYS.amplitudeApiKey, undefined, options).promise
}

export function identifyAmplitudeUser(userId: string) {
  amplitude.setUserId(userId)
}

export function getAmplitudeDeviceId() {
  return amplitude.getDeviceId()
}

export function startSessionReplay() {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: undefined,
  })
  amplitude.add(sessionReplayTracking)
}

export async function updateAmplitudeUserProperties({
  set = {},
  unset = [],
}: {
  set?: Record<string, ValidPropertyType>
  unset?: string[]
}) {
  const identify = new Amplitude.Identify()
  for (const [key, value] of Object.entries(set)) {
    identify.set(key, value)
  }
  unset.forEach((key) => {
    identify.unset(key)
  })
  await amplitude.identify(identify).promise
}
